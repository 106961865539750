interface Props {
  when: boolean,
  children: React.ReactNode
}

function ShowThenComponent(props) {
  return (
    <>
      {props.when && props.children}
    </>
  )
}

export const ShowThen = ShowThenComponent;