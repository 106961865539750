import React, { useEffect, useRef } from "react";
import AlertModalEventBus from "./alert-modal-event-bus";
import { Modal } from "bootstrap";
import { AlertModalEvents } from "./alert-modal.component";
import { createPortal } from "react-dom";
import { useAsyncState } from "../../hooks/use-async-state";

export interface AlertModalOptions {
  header: React.ReactNode;
  message: React.ReactNode;
  okButtonText: string,
  callback: () => Promise<void>
}

function AlertModalContainerComponent(props) {
  const elementId = "bdsAlertModal";
  const [options, setOptions] = useAsyncState<AlertModalOptions>({
    header: "<no header>",
    message: "<no message>",
    okButtonText: "Close",
    callback: null
  });

  function getModalInstance() {
    var element = document.getElementById(elementId);
    var alertModal = Modal.getOrCreateInstance(element, {
      keyboard: false
    });

    return alertModal;
  }

  function hideModal() {
    var modal = getModalInstance()
    modal.hide();
  }

  useEffect(() => {
    AlertModalEventBus.on(AlertModalEvents.MODAL_SHOW, function (opts: AlertModalOptions) {
      setOptions({
        header: opts.header,
        message: opts.message,
        okButtonText: opts.okButtonText,
        callback: opts.callback
      });

      var modal = getModalInstance();
      modal.show(document.body);
    });

    AlertModalEventBus.on(AlertModalEvents.MODAL_HIDE, function() {
      hideModal();
    });

    return () => {
      AlertModalEventBus.remove(AlertModalEvents.MODAL_SHOW);
      AlertModalEventBus.remove(AlertModalEvents.MODAL_HIDE);
    }

  }, [])

  function handleClose(event) {
    if (options?.callback) {
      return options.callback()
        .then((response) => {
          return hideModal();
        });
    }
    hideModal();
  }

  return (
    <>
      {
        createPortal(
          <div className="bds-alert-modal modal fade" id="bdsAlertModal" data-bs-backdrop="static"
            data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">{options?.header}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {options?.message}
                </div>
                <div className="modal-footer">
                  <button type="button" onClick={handleClose} className="btn btn-secondary">
                    {options?.okButtonText}</button>
                </div>
              </div>
            </div>
          </div>,
          document.body
        )
      }
    </>
  )
}

export const AlertModalContainer = AlertModalContainerComponent;