import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";
import { AlertModalContainer } from "../../../shared-module/components/alert-modal/alert-modal-container.component";
import { ConfirmModalContainer } from "../../../shared-module/components/confirm-modal/confirm-modal-container.component";
import { LoadingOverlayContainer } from "../../../shared-module/components/loading-overlay/loading-overlay-container.component";
import { useEffect } from "react";
import { EventBroadcasterService, SessionStateChangedEventArgs } from "../../../shared-module/events/event-broadcaster.service";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import $ from "jquery";
import { ActivityPoll } from "../activity-poll/activity-poll.component";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [location]);

  useEffect(() => {
    $(function () {
      $(window).on('keydown', function (event) {
        if (event.key === 'Enter') {
          event.preventDefault();
          return false;
        }
      });
    });

    function redirectToHomePage() {
      NavigationHelper.gotoHome(navigate);
    }

    function sessionStateChange(event: SessionStateChangedEventArgs) {
      if (event.isAuthenticated && event.remainingTimeInSeconds <= 0) {
        NavigationHelper.gotoUnauthorization(navigate);
      }
    }

    EventBroadcasterService.RegisterForSessionStateChangedEvent(sessionStateChange);
    EventBroadcasterService.RegisterApplicationSessionExpiredEvent(redirectToHomePage);
    EventBroadcasterService.RegisterForLoggedOutExpiredEvent(redirectToHomePage);
    return () => {
      EventBroadcasterService.UnregisterForSessionStateChangedEvent(sessionStateChange);
      EventBroadcasterService.UnregisterApplicationSessionExpiredEvent(redirectToHomePage);
      EventBroadcasterService.UnregisterForLoggedOutExpiredEvent(redirectToHomePage);
    }
  }, [])

  return (
    <>
      <AlertModalContainer></AlertModalContainer>
      <ConfirmModalContainer></ConfirmModalContainer>
      <ActivityPoll></ActivityPoll>
      <ToastContainer position="top-center" hideProgressBar theme='colored' />
      <LoadingOverlayContainer></LoadingOverlayContainer>
      <Outlet></Outlet>
    </>
  );
}

export default observer(App);
