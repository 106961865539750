import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { AppSettings } from "../../../app-setting";
import { EventBroadcasterService, SessionStateChangedEventArgs } from "../../../shared-module/events/event-broadcaster.service";
import { SessionResource } from "../../../shared-module/api-resources/session.resource";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { useNavigate } from "react-router-dom";
import { ApiConfig } from "shared-module/services/api-config";

function AutoExpireNotificationComponent() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);
  const sessionService = new SessionResource(ApiConfig);

  function sessionStateChanged(event: SessionStateChangedEventArgs) {
    const activate = event.isAuthenticated &&
      event.remainingTimeInSeconds <= AppSettings.autoLogoutNotificationWindowInSeconds;
    isActive != activate && setIsActive(activate);
  }

  useEffect(() => {
    EventBroadcasterService.RegisterForSessionStateChangedEvent(sessionStateChanged);
    return () => {
      EventBroadcasterService.UnregisterForSessionStateChangedEvent(sessionStateChanged);
    }
  }, []);

  function resumeSession() {
    setIsActive(false);
    return sessionService.getProfile();
  }

  function logoutSession() {
    NavigationHelper.gotoUnauthorization(navigate);
  }

  return (
    <>
      <ShowThen when={isActive}>
        <div className="bds-auto-expire-session-banner">
          <div className="container bds-container p-2">
            <div className="row">
              <div className="col-12 col-md-6 d-flex">
                <div className="align-self-center">
                  <span className="heading">Auto Expiration Notification</span>
                  {" - "}
                  <span className="message">Your session is about to expire.</span>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 col-md-5 mt-2 mt-md-0">
                    <button type="button" className="btn btn-sm bds-btn-primary w-100" onClick={resumeSession}>Resume Session</button>
                  </div>
                  <div className="col-6 mt-2 col-md-5 mt-md-0">
                    <button type="button" className="btn btn-sm bds-btn-primary w-100" onClick={logoutSession}>Log out</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShowThen >
    </>
  )
}

export const AutoExpireComponent = observer(AutoExpireNotificationComponent)
