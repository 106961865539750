import { useEffect, useState } from "react";
import { MaintenanceResource } from "../../../shared-module/api-resources/maintenance.resource";
import { EventBroadcasterService, SessionStateChangedEventArgs } from "../../../shared-module/events/event-broadcaster.service";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { observer } from "mobx-react-lite";
import { ApiConfig } from "shared-module/services/api-config";
import { BDSPepApi } from "shared-module/models/bds-pep-api.models";

function UnderMaintenanceBannerComponent() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [maintenanceSchedule, setMaintenanceSchedule] =
    useState<BDSPepApi.MaintenanceScheduleModel>(null);
  const maintenanceService = new MaintenanceResource(ApiConfig);

  function sessionStateChanged(event: SessionStateChangedEventArgs) {
    const activate = event.hasActiveMaintenance;

    console.log(isActive, activate);
    if (isActive != activate) {
      maintenanceService.getMaintenanceSchedule()
        .then((response) => {
          setMaintenanceSchedule(response?.record);
        })
        .finally(() => {
          setIsActive(activate);
        })
    }
  }

  useEffect(() => {
    EventBroadcasterService.RegisterForSessionStateChangedEvent(sessionStateChanged);
    return () => {
      EventBroadcasterService.UnregisterForSessionStateChangedEvent(sessionStateChanged);
    }
  }, [])

  return (
    <>
      <ShowThen when={maintenanceSchedule?.isMaintenanceActive}>
        <div className="bds-maintenance-banner">
          <div className="container bds-container p-2 text-center">
            <span className="bds-maintenance-heading">{maintenanceSchedule?.maintenanceTitle}</span>
            <> - </>
            <span className="bds-maintenance-message">{maintenanceSchedule?.maintenanceMessage}</span>
          </div>
        </div>
      </ShowThen>
    </>

  );
}

export const UnderMaintenanceBanner = observer(UnderMaintenanceBannerComponent);