import { Axios, AxiosResponse, InternalAxiosRequestConfig } from "axios";

export interface HttpRequestInterceptor {
  InterceptRequest: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>;
  InterceptRequestError: (error: any) => any;
  InterceptResponse: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
  InterceptResponseError: (error: any) => any;
}

export interface IApiConfig {
  axios: Axios,
  baseUrl: string;
}

export interface ParamList {
  [name: string]: string | number | boolean
}

export class ApiBaseResource {
  
  constructor(private apiConfig: IApiConfig) {
  }

  public getRequest(url: string, getParams: (ParamList | null), abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'get', getParams, null, abortController);
  }

  public postRequest(url: string, getParams: (ParamList | null), postData: any, abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'post', getParams, postData, abortController);
  }

  public postFileRequest(url: string, getParams: (ParamList | null), postData: any, abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'post', getParams, postData, abortController);
  }

  public putRequest(url: string, getParams: (ParamList | null), postData: any, abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'put', getParams, postData, abortController);
  }

  public deleteRequest(url: string, getParams: (ParamList | null), abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'delete', getParams, null, abortController);
  }

  public documentRequest(url: string, getParams: (ParamList | null), postData: any, abortController?: AbortController): Promise<any> {
    return this.docRequest(url, 'get', getParams, null, abortController);
  }

  private httpRequest(url: string, method: string, getParams: (ParamList | null), postData: any, abortController?: AbortController): Promise<any> {
    // Do not comment this line below
    console.log('httpRequest:', method + ':', url, 'params:', getParams, 'post:', postData, 'baseurl:', this.apiConfig.baseUrl);

    return this.apiConfig.axios.request({
      url: url,
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      baseURL: this.apiConfig.baseUrl,
      params: getParams || {},
      data: JSON.stringify(postData || {}),
      responseType: 'json',
      signal: abortController && abortController.signal
    })
      .then((response) => {
        var jsonResponse = JSON.parse(response.data);
        console.log('httpResponse:', jsonResponse);
        return jsonResponse;
      })
      .catch((err) => {
        throw err;
      });
  }

  private docRequest(url: string, method: string, getParams: (ParamList | null), postData: any, abortController?: AbortController): Promise<any> {
    return this.apiConfig.axios.request({
      url: url,
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      baseURL: this.apiConfig.baseUrl,
      params: getParams || {},
      data: JSON.stringify(postData || {}),
      responseType: 'blob',
      signal: abortController && abortController.signal
    })
      .then((response) => {
        var jsonResponse = response.data;
        console.log('Blob:', jsonResponse);
        return jsonResponse;
      })
      .catch((err) => {
        throw err;
      });
  }
}
