import { useEffect, useRef, useState } from "react";
import { AppSettings } from "../../../app-setting";
import { Card } from "../../../shared-module/components/card/card.component";
import { AccessIndianaCard } from "../acess-indiana-card/access-indiana-card.component";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { useNavigate } from "react-router-dom";
import { SessionResource } from "../../../shared-module/api-resources/session.resource";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { BDSPepApi } from "../../../shared-module/models/bds-pep-api.models";
import { Utility } from "../../../shared-module/helpers/utility";
import { ApiConfig } from "shared-module/services/api-config";

function HomePage() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<BDSPepApi.UserModel>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const sessionService = new SessionResource(ApiConfig);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    Utility.showLoadingOverlay();

    Promise.all([
      sessionService.isAuthenticated(),
      sessionService.getProfile()
    ])
      .then((response) => {
        setIsAuthenticated(response[0].record);
        setUser(response[1].record);
        return Promise.resolve(true);
      })
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });
  }, []);

  function handleLogout() {
    NavigationHelper.gotoUnauthorization(navigate);
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col-12 col-md-8 order-2 order-md-0">
          <ShowThen when={!isLoading && isAuthenticated}>
            <button type="button" onClick={() => handleLogout()}
              className="btn bds-btn-primary">Logout</button>
          </ShowThen>
        </div>
        <div className="col-12 col-md-2 text-start text-md-center order-1 order-md-1 mt-2 mt-md-0">
        </div>
        <div className="col-12 col-md-2 text-start text-md-end order-0 order-md-2">
        </div>
      </div>

      <div id="instructions" className="mt-4">
        <style>
          {"@media print { .no-print, .no-print * { display: none !important; } } body { font-size: 14px; }"}
        </style>
        <Card>
          <ShowThen when={!isLoading && isAuthenticated}>
            <div className="row mb-4">
              <span className="bds-welcome-heading">Welcome, {user && (user.firstName + " " + user.lastName)}</span>
            </div>
          </ShowThen>
          <div>
            <p>
              Welcome to the online application Home and Community Based Services (HCBS)
              available through the {" "}{AppSettings.bdsName} ({AppSettings.bdsAcronym}).
              This application is used to apply to become a provider offering HCBS services.
            </p>
            <div className="no-print">
              <ShowThen when={!isLoading && !isAuthenticated}>
                <AccessIndianaCard></AccessIndianaCard>
              </ShowThen>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default HomePage;
