import { useEffect } from "react";
import { SessionResource } from "../../../../shared-module/api-resources/session.resource";
import { useNavigate } from "react-router-dom";
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";
import { ApiConfig } from "shared-module/services/api-config";


function SignInOidcLandingComponent() {
  const sessionService = new SessionResource(ApiConfig);
  const navigate = useNavigate();

  useEffect(() => {
    NavigationHelper.gotoUnauthorization(navigate);
  }, []);

  return (
    <>
      <div>Redirecting ...</div>
    </>
  )
}

export const SignInOidcLanding = SignInOidcLandingComponent;