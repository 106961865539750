import { useEffect } from "react";
import { EventBroadcasterService } from "../../../shared-module/events/event-broadcaster.service";
import { AppSettings } from "../../../app-setting";
import { SessionResource } from "../../../shared-module/api-resources/session.resource";
import { ApiConfig } from "shared-module/services/api-config";

function ActivityPollComponent () {
  const sessionService = new SessionResource(ApiConfig);

  function pollActivity(): Promise<any> {
    return sessionService.checkActivityPulse()
      .then((response) => {
        var record = response.record;
        EventBroadcasterService.RaiseSessionStateChangedEvent({
          isAuthenticated: record?.isAuthenticated || false,
          remainingTimeInSeconds: record?.expirationSeconds || 0,
          hasActiveMaintenance: record?.hasActiveMaintenance
        });
      });
  }

  useEffect(() => {
    const timerId = setInterval(() => pollActivity(), AppSettings.activityPulseInterval);
    return () => clearInterval(timerId);
  }, []);

  return (
    <></>
  )
}

export const ActivityPoll = ActivityPollComponent;