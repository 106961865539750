import { toast } from 'react-toastify';
import { ReportHandler } from 'web-vitals';
import AlertModal from "../../shared-module/components/alert-modal/alert-modal.component";
import ConfirmModal from "../components/confirm-modal/confirm-modal";
import loadingOverlay from '../components/loading-overlay/loading-overlay';
import { BdsYupValidationError } from '../hooks/use-form-validation/use-form-validation.hook';
import * as Yup from "yup";

export interface SignatureStamp {
  signatureOn: string,
  signature: string
}

export interface BooleanCondition<T> {
  condition: boolean,
  value: T
}

export class Utility {

  public static ReportWebVitals(onPerfEntry?: ReportHandler): void {
    if (onPerfEntry && onPerfEntry instanceof Function) {
      import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      });
    }
  }

  public static toDate(value: string): Date {
    if (value) {
      return new Date(value);
    }
    return null;
  }

  public static toHtmlDateString(value: Date, defaultValue: string = null): string {
    if (value) {
      var yearPart = ("0000" + value.getFullYear()).slice(-4);
      var monthPart = ("00" + (value.getMonth() + 1)).slice(-2);
      var dayPart = ("00" + value.getDate()).slice(-2);
      return `${yearPart}-${monthPart}-${dayPart}`;
    }
    return defaultValue;
  }

  public static fromHtmlDateString(value: string, defaultValue: Date = null): Date {
    if (value && this.isHtmlDateStringValid(value)) {
      var yearPart = (+value.substring(0, 4));
      var monthPart = (+value.substring(5, 7)) - 1;
      var dayPart = (+value.substring(8, 10));
      var date = new Date(yearPart, monthPart, dayPart);
      return date;
    }
    return defaultValue;
  }

  public static toDateString_US_en(value: string, defaultValue: string): string {
    if (value) {
      var date = new Date(value);
      var yearPart = ("0000" + date.getFullYear()).slice(-4);
      var monthPart = ("00" + (date.getMonth() + 1)).slice(-2);
      var dayPart = ("00" + date.getDate()).slice(-2);
      return `${monthPart}/${dayPart}/${yearPart}`;
    }

    return defaultValue;
  }

  public static getToday(): Date {
    var date = new Date();
    var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return today;
  }

  public static defaultIfNull<T>(value: T, defaultValue: T): T {
    return (value == null || value == undefined) ? defaultValue : value;
  }

  public static toTriStateString<T>(value: boolean, ifTrue: string, ifFalse: string, ifNotBoolean: string): string {
    return (value === true || value === false) ? (value == true ? ifTrue : ifFalse) : ifNotBoolean;
  }

  public static numberToString(value: number, defaultValue: string): string {
    return (value == null || value == undefined) ? defaultValue : value.toString();
  }

  public static stringToNumber(value: string, defaultValue: number): number {
    return (value == null || value == undefined || value == "" || isNaN(+value)) ? defaultValue : (+value);
  }

  public static toastError(Message: string): void {
    toast(Message, { closeButton: true, autoClose: 2000, delay: 0, position: 'top-right' });
  }

  public static isGuid(value: string): boolean {
    var regEx = new RegExp(/^[0-9a-fA-Z]{8}-[0-9a-fA-Z]{4}-[0-9a-fA-Z]{4}-[0-9a-fA-Z]{4}-[0-9a-fA-Z]{12}$/i);
    return regEx.test(value);
  }

  public static isHtmlDateStringValid(dateString: string): boolean {
    return new RegExp(/^[1-9][0-9]{3}-\d{2}-\d{2}$/).test(dateString);
  }

  public static mergeZipCodeParts(zipcode: string, zipPlus4: string, defaultValue: string): string {
    return !zipcode ? defaultValue : zipPlus4 ? `${zipcode}-${zipPlus4}` : zipcode;
  }

  public static alert(title: React.ReactNode, message: React.ReactNode, callback: () => Promise<void> = null): void {
    AlertModal.showModal({ header: title, message: message, okButtonText: "Ok", callback });
  }

  public static confirm(title: React.ReactNode, message: React.ReactNode, callback: (boolean) => Promise<void>): void {
    ConfirmModal.showModal({ header: title, message: message, yesButtonText: "Yes", noButtonText: "No", callback });
  }

  private static overlayTimer = null;

  public static showLoadingOverlay(): void {
    // is there a hide request pending?
    // if yes remove it, we do not need it
    if (Utility.overlayTimer) {
      clearTimeout(Utility.overlayTimer);
      Utility.overlayTimer = null;
    }

    loadingOverlay.show();
  }

  public static hideLoadingOverlay(debounce: number = 300): void {
    // is there a hide request pending?
    // if yes remove it, we would debounce it
    if (Utility.overlayTimer) {
      clearTimeout(Utility.overlayTimer);
      Utility.overlayTimer = null;
    }

    Utility.overlayTimer = setTimeout(() => {
      loadingOverlay.hide();
    }, debounce);
  }

  public static getBooleanToTypedResult<T>(conditions: BooleanCondition<T>[], defaultValue: T)
    : T {
    var found = conditions.find((item) => {
      return item.condition;
    });
    return found ? found.value : defaultValue;
  }

  public static buildYupErrorMessage(key: string, message: string): Yup.CreateErrorOptions {
    return { message: { key: key, message: message } as Yup.Message<BdsYupValidationError> };
  }

  public static getApplicantStamp(name: string, applicationId: string): SignatureStamp {
    return Utility.createSignatureCapture(name, applicationId);
  }

  public static getLegalRepresentativeStamp(name: string, applicationId: string): SignatureStamp {
    return Utility.createSignatureCapture(name, applicationId);
  }

  private static createSignatureCapture(name, applicationId): SignatureStamp {
    var tzoffset = (new Date()).getTimezoneOffset() * 60000;
    var sigStamp = Utility.substr(new Date(Date.now() - tzoffset).toISOString(), 0, 19);
    var signatureString = sigStamp + (name || "") + applicationId;
    return {
      signatureOn: sigStamp,
      signature: signatureString
    };
  }

  private static substr(value: string, index: number, length: number): string {
    return !value ? value : (value.length <= length ? value : value.substring(index, length));
  }
}