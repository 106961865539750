import { BDSPepApi } from "../models/bds-pep-api.models";
import { ApiBaseResource, IApiConfig } from "./api.base.resource";

export class SessionResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

  public isAuthenticated(abortController?: AbortController)
    : Promise<BDSPepApi.ApiBooleanResponse> {
    return super.getRequest(`api/v1/profile/is-authenticated`, null,
      abortController) as Promise<BDSPepApi.ApiBooleanResponse>;
  }

  public getProfile(abortController?: AbortController)
    : Promise<BDSPepApi.ApiSingleResponseOfUserModel> {
    return super.getRequest(`api/v1/profile/me`, null,
      abortController) as Promise<BDSPepApi.ApiSingleResponseOfUserModel>;
  }

  public checkActivityPulse(abortController?: AbortController)
    : Promise<BDSPepApi.ApiSingleResponseOfUserActivityModel> {
    return super.getRequest(`api/v1/system/get-activity`, null,
      abortController) as Promise<BDSPepApi.ApiSingleResponseOfUserActivityModel>;
  }

}