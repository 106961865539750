import { useNavigate } from "react-router-dom";
import { Card } from "../../../shared-module/components/card/card.component";

function PageNotFound() {
  const navigate = useNavigate();

  function gotoHome() {
      navigate("/");
  }
  
  return (
    <>
      <div className="mt-4">
        <Card>
          <div className="bds-page-not-found-message">Page Not Found.</div>
        </Card>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-md-6">
        </div>
        <div className="col-12 col-md-6 text-start text-md-end mt-3 mt-md-0">
          <button type="button" onClick={gotoHome}
            className="btn bds-btn-primary">Go to Home</button>
        </div>
      </div>
    </>

  );
}

export default PageNotFound;