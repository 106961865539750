import { Dispatch, useRef, useState } from "react";

export function useAsyncState<T>(initialState: T): [T, Dispatch<T>] {
  const [localState, _setLocalState] = useState<T>(initialState);
  const localStateRef = useRef<T>(localState);

  const setLocalState: Dispatch<T> = (data: T) => {
    localStateRef.current = data;
    _setLocalState(data);
  };

  return [localState, setLocalState];
}