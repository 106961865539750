import confirmModalEventBus from "./confirm-modal-event-bus";
import { ConfirmModalOptions } from "./confirm-modal-container.component";

export enum ConfirmModalEvents {
  MODAL_SHOW = "CONFIRM.MODAL.SHOW",
  MODAL_HIDE = "CONFIRM.MODAL.HIDE"
}

class ConfirmModal {
  constructor() {
  }

  public showModal(options: ConfirmModalOptions) {
    confirmModalEventBus.dispatch(ConfirmModalEvents.MODAL_SHOW, options);
  }

  public hideModal() {
    confirmModalEventBus.dispatch(ConfirmModalEvents.MODAL_HIDE, null);
  }
}

export default new ConfirmModal();
