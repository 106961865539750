class ConfirmModalEventBus {
  private listeners: { event: string, callback: any }[] = [];

  constructor() {
  }

  public on(event, callback) {
    var listener: { event, callback };
    this.listeners.push(listener);
    document.addEventListener(event, (e) => callback(e.detail));
    console.debug(event, ': ConfirmModal listener added');
  }

  public dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  }

  public remove(event) {
    var listener = this.listeners.find((item) => {
      return item.event == event;
    });

    if (listener) {
      document.removeEventListener(listener.event, listener.callback);
      console.debug(event, ': ConfirmModal listener removed');
    }

    console.debug(event, ': ConfirmModal listener not found - not removed');
  }
};

export default new ConfirmModalEventBus;
