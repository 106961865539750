import fssaLogo from "../../../../assets/images/fssa_logo.png";

function MainHeaderComponent() {
  return (
    <div className="main-header-container">
      <div className="container bds-container main-header d-flex">
        <div className="p-3">
          <img className="fssa-logo" src={fssaLogo} />
        </div>
        <div className="contents align-middle p-3">
          <a href="/" className="site-name">Application for BDS Services</a>
        </div>
      </div>
    </div>
  );
}

export const MainHeader = MainHeaderComponent;

