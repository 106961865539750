import { BDSPepApi } from "../models/bds-pep-api.models";
import { ApiBaseResource, IApiConfig } from "./api.base.resource";

export class MaintenanceResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

  public getMaintenanceSchedule(abortController?: AbortController)
    : Promise<BDSPepApi.ApiSingleResponseOfMaintenanceScheduleModel> {
    return super.getRequest(`api/v1/maintenance/schedule`, null,
      abortController) as Promise<BDSPepApi.ApiSingleResponseOfMaintenanceScheduleModel>;
  }
}