import { NavigateFunction } from "react-router-dom";

export class NavigationHelper {
  private static routes = {
    home: "/home",
    startApplication: "/start-application",
    authorize: "/authorize",
    unauthorize: "/unauthorize",
    registeration: "https://access.in.gov/signup/"
  }

  public static gotoHome(navigate: NavigateFunction): void {
    navigate(this.routes.home);
  }

  public static gotoHomeWithReplace(navigate: NavigateFunction): void {
    navigate(this.routes.home, {
      replace: true,
      preventScrollReset: true
    });
  }

  public static gotoStartApplication(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.startApplication + '/' + id);
  }

  public static gotoStartApplicationWithReplace(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.startApplication + '/' + id, {
      replace: true,
      preventScrollReset: true
    });
  }

  public static gotoAuthorization(navigate: NavigateFunction): void {
    navigate(this.routes.authorize);
  }

  public static gotoUnauthorization(navigate: NavigateFunction): void {
    navigate(this.routes.unauthorize);
  }

  public static gotoRegisteration(navigate: NavigateFunction): void {
    navigate(this.routes.authorize);
  }
}