import LoadingOverlayEventBus from "./loading-overlay-event-bus";

export enum LoadingOverlayEvents {
    OVERLAY_SHOW = "LOADING.OVERLAY.SHOW",
    OVERLAY_HIDE = "LOADING.OVERLAY.HIDE"
}

class LoadingOverlay {
    constructor() {
    }

    public show() {
        LoadingOverlayEventBus.dispatch(LoadingOverlayEvents.OVERLAY_SHOW, {});
    }

    public hide() {
        LoadingOverlayEventBus.dispatch(LoadingOverlayEvents.OVERLAY_HIDE, null);
    }
}

export default new LoadingOverlay();
