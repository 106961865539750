import React from 'react';

interface Props {
    children: React.ReactNode;
}
function CardComponent({ children }: Props) {
    return (
        <div className="main-card">
            {children}
        </div>
    );
}

export const Card = CardComponent;