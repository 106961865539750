import { Axios, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { IApiConfig } from "../api-resources/api.base.resource";
import { AppSettings } from "../../app-setting";
import { BDSPepApiRequestInterceptor } from "../http-interceptor/http-request-interceptor";

class ApiConfigImp implements IApiConfig {
    public axios: Axios;
    public baseUrl: string;
  
    constructor() {
      this.axios = new Axios({});
      const interceptor = new BDSPepApiRequestInterceptor();
  
      this.axios.interceptors.request.use(
        (config: InternalAxiosRequestConfig<any>) => interceptor.InterceptRequest(config),
        (error: any) => interceptor.InterceptRequest(error));
  
      this.axios.interceptors.response.use(
        (response: AxiosResponse<any>) => interceptor.InterceptResponse(response),
        (error: any) => interceptor.InterceptResponseError(error));
  
      this.baseUrl = AppSettings.apiUrl
    }
  }

  export const ApiConfig = new ApiConfigImp();