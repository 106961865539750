import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { HttpRequestInterceptor } from "../api-resources/api.base.resource";
import { EventBroadcasterService } from "../events/event-broadcaster.service";

export class BDSPepApiRequestInterceptor implements HttpRequestInterceptor {
  constructor() {
  }

  public InterceptRequest(config: InternalAxiosRequestConfig)
    : InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> {
    /* Add code here */
    return config;
  }

  public InterceptRequestError(error: any): any {
    /* Add code here */
    return Promise.reject(error);
  }

  public InterceptResponse(response: AxiosResponse)
    : AxiosResponse | Promise<AxiosResponse> {

    this.handleApplicationSessionExpired(response);

    if (response.status >= 300 && response.status < 400) {
      console.log('Status Codes: [300, 399]:', response);
      return {} as any;
    }

    if (response.status >= 400) {
        console.log('Status Codes: [400, 401[:', response);
        throw response.data;
    }

    return response;
  }

  public InterceptResponseError(error: any): any {

    return Promise.reject(error);
  }

  private handleApplicationSessionExpired(response: AxiosResponse) {
    if (response.status == 410) {
      EventBroadcasterService.RaiseApplicationSessionExpiredEvent();
    }
  }

}