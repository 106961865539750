import App from "./components/_app/app.component";
import HomePage from "./components/home-page/home-page.component";
import PageNotFound from "./components/page-not-found/page-not-found.component";
import { RouteObject } from "react-router-dom";
import { OidcAuthorize } from "./components/oidc/oidc-authorize/oidc-authorize";
import { OidcUnauthorize } from "./components/oidc/oidc-unauthorize/oidc-unauthorize";
import { SignInOidcLanding } from "./components/oidc/signin-landing-oidc/signin-landing-oidc";
import { SignOutOidcLanding } from "./components/oidc/signout-landing-oidc/signout-landing-oidc";
import { Layout } from "./components/_layout/layout.component";
import { ErrorBoundary } from "../shared-module/components/error-boundary/error-boundary";

function routes() {
  return [
    {
      path: "/"
    },
    {
      path: "",
      element: <App></App>,
      errorElement: <ErrorBoundary></ErrorBoundary>,
      children: [
        {
          path: "",
          element: <Layout></Layout>,
          children: [
            { index: true, element: <HomePage /> },
            { path: "home", element: <HomePage /> },
          ]
        },
        { path: "authorize", element: <OidcAuthorize /> },
        { path: "unauthorize", element: <OidcUnauthorize /> },
        { path: "signin-landing-oidc", element: <SignInOidcLanding /> },
        { path: "signout-landing-oidc", element: <SignOutOidcLanding /> },
        {
          path: "*",
          element: <Layout></Layout>,
          children: [
            { path: "*", element: <PageNotFound /> }
          ]
        }
      ]
    },
  ] as RouteObject[];
};

export default routes();
