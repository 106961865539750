import { AppSettings } from "../../../../app-setting";
import bdsGraphic from "../../../../assets/images/bds_graphic.png"

function MainSubHeaderComponent() {
  return (
    <div className="main-sub-header-container">
      <div className="container bds-container main-sub-header d-flex">
        <div className="p-3">
          <img className="bds-graphic" src={bdsGraphic} />
        </div>
        <div className="contents align-middle pt-3 pb-5">
          The { AppSettings.bdsName } envisions a community where all people
          have equal access and opportunity to realize their good life. We are committed
          to partnering with individuals, and their families, and professionals to ensure
          each person centered services that empower people with disabilities and their
          families by connecting them to the resources and supports to live their best life.
          Our core value is that all people have the right to live, love, work, learn,
          play, and pursue their dreams.
        </div>
      </div>
    </div>
  );
}

export const MainSubHeader = MainSubHeaderComponent;