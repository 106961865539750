import { AppSettings } from "app-setting";
import { useEffect } from "react";

function OidcUnauthorizeComponent() {

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(AppSettings.apiUrl + "/auth/unauthorize");
    }, 2);
  }, []);

  return (
    <>
      <div className="bds-redirection-overlay">
        <div className="text-center">
          <div className="mb-4">Redirecting to External Website ...</div>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Redirecting...</span>
          </div>
        </div>
      </div>
    </>
  )
}

export const OidcUnauthorize = OidcUnauthorizeComponent;