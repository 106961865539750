import React, { useEffect, useState } from "react";
import ConfirmModalEventBus from "./confirm-modal-event-bus";
import { Modal } from "bootstrap";
import { ConfirmModalEvents } from "./confirm-modal";
import { createPortal } from "react-dom";
import { useAsyncState } from "../../hooks/use-async-state";

export interface ConfirmModalOptions {
  header: React.ReactNode;
  message: React.ReactNode;
  yesButtonText: string;
  noButtonText: string;
  callback: (yes) => Promise<void>;
}

function ConfirmModalContainerComponent(props) {
  const elementId = "bdsConfirmModal";
  const [options, setOptions] = useAsyncState<ConfirmModalOptions>({
    header: "",
    message: "",
    yesButtonText: "Yes",
    noButtonText: "No",
    callback: null
  });

  function getModalInstance() {
    var element = document.getElementById(elementId);
    var alertModal = Modal.getOrCreateInstance(element, {
      keyboard: false
    });

    return alertModal;
  }

  useEffect(() => {
    var confirmModal = getModalInstance();

    ConfirmModalEventBus.on(ConfirmModalEvents.MODAL_SHOW, function (opts: ConfirmModalOptions) {
      setOptions({
        header: opts.header,
        message: opts.message,
        yesButtonText: opts.yesButtonText,
        noButtonText: opts.noButtonText,
        callback: opts.callback
      });

      confirmModal.show(document.body);
    });

    ConfirmModalEventBus.on(ConfirmModalEvents.MODAL_HIDE, () => {
      confirmModal.hide();
    });

  }, [])

  function handleNo(event) {
    options.callback(false)
      .then((response) => {
        var modal = getModalInstance();
        modal.hide();
      });
  }

  function handleYes(event) {
    options.callback(true)
      .then((response) => {
        var modal = getModalInstance();
        modal.hide();
      });
  }

  return (
    <>
      {
        createPortal(
          <div className="bds-confirm-modal modal fade" id="bdsConfirmModal" data-bs-backdrop="static"
            data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">{options.header}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {options.message}
                </div>
                <div className="modal-footer">
                  <button type="button"
                    onClick={(event) => handleNo(event)}
                    className="btn btn-secondary">{options.noButtonText}</button>
                  <button type="button"
                    onClick={(event) => handleYes(event)}
                    className="btn btn-primary">{options.yesButtonText}</button>
                </div>
              </div>
            </div>
          </div>,
          document.body
        )
      }
    </>
  )
}

export const ConfirmModalContainer = ConfirmModalContainerComponent;