
import { AppSettings } from "../../../app-setting";
import pkg from "./../../../../package.json";


function DevelopmentBannerComponent() {
  if (AppSettings.environment.toUpperCase() !== 'PRODUCTION') {
    return <div className="container-fluid py-1 align-center"
      style={{ color: "#000000", backgroundColor: "#f0f0f0" }}>
      {AppSettings.environment} - version: {pkg.version}</div>
  }
  else {
    <></>
  }
}

export const DevelopmentBanner = DevelopmentBannerComponent;